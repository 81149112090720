<template>
	<div>
		<v-layout class="flex-wrap">
			<v-spacer></v-spacer>
			<v-flex class="py-2 my-auto d-flex text-right listing-right">
				<template>
					<v-flex class="d-flex justify-content-end mr-1">
						<div class="title-search ml-3">
							<v-autocomplete
								v-model="filter_user"
								:items="subjectItems"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								placeholder="Search by subject"
								class="filterSelect"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</div>
					</v-flex>
					<v-btn depressed color="blue darken-4" class="text-white" tile>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
					<v-btn depressed color="blue darken-4" class="text-white" tile>
						<span>View Calendar</span>
					</v-btn>
					<v-btn depressed color="blue darken-4" class="text-white" tile>
						<span>Unsheduled Activity</span>
					</v-btn>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="viewType = !viewType"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn>
					<v-btn depressed color="blue darken-4" class="text-white" tile>
						<v-icon>mdi-reload</v-icon>
					</v-btn>
				</template>
			</v-flex>
		</v-layout>
		<v-row class="fill-height">
			<v-col cols="12" lg="3" md="4" sm="4">
				<v-sheet style="overflow: auto; height: calc(100vh - 155px)">
					<v-form ref="overviewForm" lazy-validation>
						<v-col cols="12">
							<v-row>
								<v-col cols="12" lg="12">
									<label for="resources" class="btx-label mt-2">Resources</label>
									<v-autocomplete
										class="mt-0"
										hide-details
										outlined
										placeholder=""
										:items="resourcesList"
										v-model="resources"
									>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" lg="12">
									<label for="users-teams" class="btx-label mt-2">Users/Teams</label>
									<v-autocomplete
										class="mt-0"
										hide-details
										outlined
										placeholder=""
										:items="users_teamsList"
										v-model="users_teams"
									>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" lg="12">
									<label for="views" class="btx-label mt-2">Views</label>
									<v-autocomplete
										class="mt-0"
										hide-details
										outlined
										placeholder=""
										:items="views_teamsList"
										v-model="views_teams"
									>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row>
								<v-col cols="12" lg="12">
									<div class="d-flex">
										<label for="activities" class="btx-label mt-2 me-2">Activities</label>
										<v-checkbox
											class="mt-0"
											color="grey"
											value="primary"
											hide-details
											v-model="activity_checkbox"
										></v-checkbox>
									</div>
								</v-col>
								<v-col cols="12" lg="6">
									<v-checkbox
										class="mt-0"
										label="Task"
										color="success"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Phone Call"
										color="orange"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Letter"
										color="cyan"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Service Activity"
										color="indigo"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Recurring Appoinment"
										color="grey"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Social Activity"
										color="green"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
								</v-col>
								<v-col cols="12" lg="6">
									<v-checkbox
										class="mt-0"
										label="Fax"
										color="pink"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Email"
										color="blue"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Appoinment"
										color="indigo"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Campaign Activity"
										color="success"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Birthday"
										color="green"
										value="primary"
										hide-details
										v-model="activity_checkbox"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row>
								<v-col cols="12" lg="12">
									<div class="d-flex">
										<label for="status" class="btx-label mt-2 me-2">Status</label>
										<v-checkbox class="mt-0" color="grey" hide-details v-model="status_chackbox"></v-checkbox>
									</div>
								</v-col>
								<v-col cols="12" lg="6">
									<v-checkbox
										class="mt-0"
										color=""
										label="Open"
										hide-details
										v-model="status_chackbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										color=""
										label="Cancelled"
										hide-details
										v-model="status_chackbox"
									></v-checkbox>
								</v-col>
								<v-col cols="12" lg="6">
									<v-checkbox
										class="mt-0"
										color=""
										label="Completed"
										hide-details
										v-model="status_chackbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										color=""
										label="Scheduled"
										hide-details
										v-model="status_chackbox"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-row>
								<v-col cols="12" lg="12">
									<div class="d-flex">
										<label for="priority" class="btx-label mt-2 me-2">Priority</label>
										<v-checkbox
											class="mt-0"
											color="grey"
											hide-details
											v-model="priority_chackbox"
										></v-checkbox>
									</div>
								</v-col>
								<v-col cols="12" lg="12">
									<v-checkbox
										class="mt-0"
										label="High"
										color="red"
										hide-details
										v-model="priority_chackbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Normal"
										color="yellow"
										hide-details
										v-model="priority_chackbox"
									></v-checkbox>
									<v-checkbox
										class="mt-0"
										label="Low"
										color="green"
										hide-details
										v-model="priority_chackbox"
									></v-checkbox>
								</v-col>
							</v-row>
						</v-col>
					</v-form>
				</v-sheet>
			</v-col>
			<v-col cols="12" md="8" lg="9">
				<v-sheet>
					<v-toolbar flat height="40px" class="px-0 calendar-header">
						<v-btn outlined depressed tile class="mr-2" color="grey darken-2" @click="setToday">
							Today
						</v-btn>
						<v-btn outlined depressed tile text small class="mr-2" color="grey darken-2" @click="prev">
							<v-icon> mdi-chevron-left </v-icon>
						</v-btn>
						<v-btn outlined depressed text small tile color="grey darken-2" @click="next">
							<v-icon> mdi-chevron-right </v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<div class="mx-auto">
							<v-toolbar-title v-if="$refs.calendar" class="font-level-3-bold">
								{{ $refs.calendar.title }}
							</v-toolbar-title>
						</div>
						<v-spacer></v-spacer>
						<v-btn-toggle
							v-model="showSelected"
							tile
							depressed
							active-class="blue darken-4"
							color="white"
						>
							<v-btn value="4day" @click="type = '4day'"> 4 Day </v-btn>
							<v-btn value="day" @click="type = 'day'"> Day </v-btn>
							<v-btn value="week" @click="type = 'week'"> Week </v-btn>
							<v-btn value="month" @click="type = 'month'"> Month </v-btn>
						</v-btn-toggle>
					</v-toolbar>
				</v-sheet>
				<v-sheet style="overflow: auto; height: calc(100vh - 190px)">
					<v-calendar
						ref="calendar"
						v-model="focus"
						color="primary"
						:events="events"
						:event-color="getEventColor"
						:type="type"
						@click:event="showEvent"
						@click:more="viewDay"
						@click:date="viewDay"
						@change="updateRange"
					></v-calendar>
					<EventDetails
						:drawer="open_event"
						:data-event="selectedEvent"
						v-on:close="open_event = false"
					></EventDetails>
				</v-sheet>
			</v-col>
		</v-row>
	</div>
</template>
<script>
import EventDetails from "@/view/module/calendar/EventDetails";
export default {
	name: "Calendar",
	data: () => ({
		subjectItems: [],
		filter_user: null,
		viewType: false,
		showSelected: "month",
		resources: "Users/Teams",
		resourcesList: ["Users/Teams"],
		users_teams: "Juan Carlos",
		users_teamsList: ["Juan Carlos"],
		views_teams: "Top Down View",
		views_teamsList: ["Top Down View"],
		activity_checkbox: false,
		status_chackbox: false,
		priority_chackbox: false,
		open_event: false,
		focus: "",
		type: "month",
		typeToLabel: {
			month: "Month",
			week: "Week",
			day: "Day",
			"4day": "4 Days",
		},
		filterType: "task",
		typeOfFilter: {
			task: "Task",
			meeting: "Meeting",
			leave: "Leave",
		},
		selectedEvent: {},
		selectedElement: null,
		selectedOpen: false,
		events: [],
		colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
		names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
		eventdate: [1, 3, 4, 15, 27, 8, 10, 20, 22, 13, 19],
	}),
	mounted() {
		this.$refs.calendar.checkChange();
	},
	methods: {
		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
		},
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = "";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.open_event = true;
				requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},
		updateRange({ start, end }) {
			const events = [];
			const min = new Date(`${start.date}T00:00:00`);
			const max = new Date(`${end.date}T23:59:59`);
			// const days = (max.getTime() - min.getTime()) / 86400000;
			// const eventCount = this.rnd(days, days + 20);
			const eventCount = this.eventdate.length;
			console.log(eventCount);

			for (let i = 0; i < eventCount; i++) {
				const allDay = this.eventdate[i];
				const firstTimestamp = this.rnd(min.getTime(), max.getTime());
				const first = new Date(firstTimestamp - (firstTimestamp % 900000));
				const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
				const second = new Date(first.getTime() + secondTimestamp);

				events.push({
					name: this.names[this.rnd(0, this.names.length - 1)],
					start: first,
					end: second,
					color: this.colors[this.rnd(0, this.colors.length - 1)],
					timed: !allDay,
				});
			}

			this.events = events;
			console.log("events");
			console.log(events);
		},
		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
	},
	components: {
		EventDetails,
	},
};
</script>
